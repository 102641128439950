import DataLayerController from "../Controllers/DataLayerController";

const Utils = {
    searchBoxEl: {
        desktopSelector: "#search-box",
        mobileSelector: "#mobile-search-box",
    },

    clearButtonEl: {
        skillSelector: ".delete-skill-js",
        locationSelector: ".delete-location-js",
    },

    locationEl: {
        selector: "#location",
    },

    mobileClearEl: {
        selector: ".mobile-clear-js",
    },

    skillEvent: {
        event: "skill-clicked",
    },

    locationEvent: {
        event: "location-clicked",
    },

    checkMobileSearchBox() {
        return document.querySelectorAll(this.searchBoxEl.mobileSelector)
            .length;
    },

    setDefaultSearcherListeners(searchClient) {
        $(this.searchBoxEl.mobileSelector).on("change", (ev) =>
            this.changeMobileInput(ev, searchClient)
        );
        $(this.mobileClearEl.selector).on("click", (ev) =>
            this.mobileClear(ev, searchClient)
        );
        $(this.clearButtonEl.skillSelector).on("click", (ev) =>
            this.skillClearEv(ev, searchClient)
        );
        window.addEventListener('searchCleared', (ev) => {
            this.locationClearEv(ev, searchClient)
        });
    },

    setKeyUpEnterListener(searchClient) {
        $(
            `${this.searchBoxEl.desktopSelector}, ${this.locationEl.selector}`
        ).on("keydown", (ev) => {
            this.searchInputKeyPressed(ev, searchClient);
        });
    },

    skillClearEv(ev, searchClient) {
        this.mobileClear(ev, searchClient);
    },

    locationClearEv(ev, searchClient) {
        Utils.runSearch(ev, searchClient);
    },

    suggestionsListeners(searchClient) {
        document.addEventListener(this.skillEvent.event, (ev) =>
            this.skillClicked(ev, searchClient)
        );
        document.addEventListener(this.locationEvent.event, (ev) =>
            this.locationClicked(ev, searchClient)
        );
    },

    skillClicked(ev, searchClient) {
        const query = ev.detail.trim();
        searchClient.helper.setQuery(query).search();
        DataLayerController.searchDataPush(query);
    },

    locationClicked(ev, searchClient) {
        Utils.runSearch(ev, searchClient);
    },

    changeMobileInput(ev, searchClient) {
        const query = ev.currentTarget.value.trim();
        console.log("query", query);
        setTimeout(() => {
            $(this.searchBoxEl.desktopSelector).val(query);
            $(this.searchBoxEl.mobileSelector).val(query);
            Utils.runSearch(ev, searchClient);
        }, 500);
    },

    mobileClear(ev, searchClient) {
        $(this.searchBoxEl.desktopSelector).val("");
        $(this.searchBoxEl.mobileSelector).val("");
        searchClient.helper.setQuery("").search();
    },

    getQueryParameterFromUrl() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(
            `${import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX}[query]`
        );
    },

    locationCityIndex(input) {
        return window.cities.findIndex(
            (c) => c.name.toLowerCase() === input.toLowerCase()
        );
    },

    locationCountryIndex(input) {
        return window.countries.findIndex(
            (country) => country === input.toLowerCase()
        );
    },

    buildLocationLists() {
        let countries = [];
        let cities = [];
        for (const location in window.initialLocations) {
            const locationArr = location.split(" > ");
            const country = locationArr[0];
            const city = locationArr[1];

            if (!countries.includes(country.toLowerCase())) {
                countries.push(country.toLowerCase());
            }

            if (cities.findIndex((c) => c.name === city) === -1) {
                const obj = {
                    name: city.toLowerCase(),
                    searchValue: location.toLowerCase(),
                    country: country.toLowerCase(),
                };
                cities.push(obj);
            }
        }

        window.countries = countries;
        window.cities = cities;
    },

    findCitiesByCountry(country) {
        return window.cities.filter((c) => c.country.toLowerCase() === country);
    },

    getSelectedFiltersNumber(items) {
        return items.filter((item) => item.isRefined === true).length;
    },

    setSelectedRefinementsNumber(n, selector) {
        const counterContainer = $(selector);
        if (n) {
            counterContainer.show();
            counterContainer.text(n);
        } else {
            counterContainer.hide();
        }
    },

    searchInputKeyPressed(ev, searchClient) {
        if (ev.key === "Enter") {
            setTimeout(() => {
                ev.currentTarget.blur();
                var event = new CustomEvent("search-executed", { detail: "" });
                document.dispatchEvent(event);
                this.runSearch(ev, searchClient);
            }, 500);
        }
    },

    runSearch(ev, searchClient) {
        const query = $(this.searchBoxEl.desktopSelector).val().trim();
        $(this.searchBoxEl.mobileSelector).val(query);
        searchClient.helper.setQuery(query).search();
        window.searched = true;
        DataLayerController.searchDataPush(query);
    },

    buildSearchUrl(locations = true, query = false) {
        let url = window.location.origin + "/jobs";
        url = this.buildQueryUrl(url, query);
        if (locations) url = this.buildLocationsUrl(url);
        return url;
    },

    buildQueryUrl(url, query = false) {
        const queryValue = query
            ? query
            : $(this.searchBoxEl.desktopSelector).val();
        return queryValue
            ? url +
                  `?${
                      import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX
                  }[query]=${queryValue}`
            : url;
    },

    buildLocationsUrl(url) {
        const locationValue = $("#location").val();
        if (!locationValue) return url;

        const countryIndex = this.locationCountryIndex(locationValue);
        const cityIndex = this.locationCityIndex(locationValue);
        const connector = this.isQueryAlreadyInUrl(url) ? "&" : "?";

        if (cityIndex !== -1) {
            const cityValue = window.cities[cityIndex].searchValue;
            url += `${connector}${
                import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX
            }[refinementList][locations][0]=${cityValue}`;
        } else if (countryIndex !== -1) {
            const cities = this.findCitiesByCountry(
                window.countries[countryIndex]
            );
            cities.forEach((city, index) => {
                const paramKey = `${
                    import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX
                }[refinementList][locations][${index}]=`;
                if (index === 0) {
                    url += `${connector}${paramKey}${city.searchValue}`;
                } else {
                    url += `&${paramKey}${city.searchValue}`;
                }
            });
        }

        if (cityIndex === -1 && countryIndex === -1) {
            url += `${connector}${
                import.meta.env.VITE_MEILI_JOB_OFFERS_INDEX
            }[refinementList][locations][0]=${locationValue}`;
        }
        url += `&locationInput=${locationValue}`;

        return url;
    },

    isQueryAlreadyInUrl(url) {
        return url.includes("[query]");
    },
};

export default Utils;
